enum HeaderStyle {
  CAPACITY = 'capacity',
  NO_FLIGHT = 'no flight',
  SERVER = 'server',
  UNKNOWN = 'unknown',
}

enum FooterStyle {
  CAPACITY = 'CAPACITY',
  FLIGHT_INFO_NOT_FOUND = 'FLIGHT_INFO_NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export { FooterStyle, HeaderStyle };
