import { ApolloError } from '@collinsonx/utils/apollo';

function fetchErrorObject(response: ApolloError | unknown) {
  if (!response && typeof response !== 'object') {
    return null;
  }

  if ((response as ApolloError) && (response as ApolloError).graphQLErrors) {
    const { graphQLErrors } = response as ApolloError;

    if (graphQLErrors && typeof graphQLErrors === 'object') {
      const data = graphQLErrors[0];

      if (!data) {
        return null;
      }

      if ('extensions' in data) {
        return data.extensions;
      }
    }
  } else {
    return { error: response };
  }

  return null;
}

export default fetchErrorObject;
