export const AVAILABLE_SLOTS_ERRORS = {
  RESPONSE_IS_EMPTY: {
    code: 'ERR_BOOKING_AVAILABLE_SLOTS_RESPONSE_IS_EMPTY',
  },
  SNAPLOGIC: {
    CATCH_ERROR: {
      code: 'ERR_BOOKING_AVAILABLE_SLOTS_SNAPLOGIC_CATCH_ERROR',
    },
    ENOUGH_CAPACITY: {
      code: 'ERR_BOOKING_AVAILABLE_SLOTS_NOT_ENOUGH_CAPACITY',
    },
    VALIDATION_ERROR: {
      code: 'ERR_BOOKING_AVAILABLE_SLOTS_SNAPLOGIC_VALIDATION_ERROR',
    },
  },
};
