import { Box } from '@collinsonx/design-system/core';
import useLocale from 'hooks/useLocale';

function ServerErrorMessage() {
  const translations = useLocale();
  const message1 = translations.lounge.errors.server.description;
  return (
    <>
      <Box>{message1}</Box>
    </>
  );
}

export default ServerErrorMessage;
