import {
  Box,
  Center,
  Divider,
  Modal,
  Stack,
  Title,
} from '@collinsonx/design-system/core';
import { useDisclosure } from '@collinsonx/design-system/hooks';
import useLocale from 'hooks/useLocale';
import { FC } from 'react';
import colors from 'ui/colour-constants';

import type { ComponentProps } from './props';

import {
  CapacityErrorMessage,
  Footer,
  NoFlightInfoErrorMessage,
  ServerErrorMessage,
} from './components';
import { FooterStyle, HeaderStyle } from './enums';
import { setHeaderTitle } from './helpers';

export function internalServerError() {
  return generateError(
    HeaderStyle.SERVER,
    FooterStyle.INTERNAL_SERVER_ERROR,
    <ServerErrorMessage />
  );
}

export function capacityDefaultError() {
  return generateError(
    HeaderStyle.CAPACITY,
    FooterStyle.CAPACITY,
    <CapacityErrorMessage />
  );
}

export function flightInfoNotFoundError() {
  return generateError(
    HeaderStyle.NO_FLIGHT,
    FooterStyle.FLIGHT_INFO_NOT_FOUND,
    <NoFlightInfoErrorMessage />
  );
}

function generateError(
  header: HeaderStyle,
  footer: FooterStyle,
  Message: JSX.Element
) {
  return (
    <AvailableSlotsModal
      footerStyle={footer}
      headerStyle={header}
      Message={Message}
    />
  );
}

const AvailableSlotsModal: FC<ComponentProps> = ({
  footerStyle,
  headerStyle,
  Message,
}) => {
  const [opened, { close }] = useDisclosure(true);
  const translations = useLocale();

  return (
    <Modal onClose={close} opened={opened} p="0" padding={0}>
      <Center>
        <Stack gap={0}>
          <Box>
            <Title
              size="h3"
              style={{
                textAlign: 'center',
              }}
            >
              {setHeaderTitle(headerStyle, translations.lounge.errors)}
            </Title>
          </Box>
          <Stack style={{ padding: '1rem' }}>{Message}</Stack>
          <Divider m={0} my="sm" style={{ marginBottom: '0px' }} />
          <Stack bg={colors.dialogFooter} p="lg">
            <Footer close={close} footerStyle={footerStyle} />
          </Stack>
        </Stack>
      </Center>
    </Modal>
  );
};
