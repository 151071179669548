import { Box } from '@collinsonx/design-system/core';
import useLocale from 'hooks/useLocale';
import { FC } from 'react';

import type { CapacityProps } from '../props';

import RetryOptions from './RetryOptions';

const CapacityMessage: FC<CapacityProps> = () => {
  const translations = useLocale();
  const message = translations.lounge.errors.capacity.description.known;

  return (
    <>
      <Box>{message}</Box>
      <RetryOptions />
    </>
  );
};

export default CapacityMessage;
