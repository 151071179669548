import { Box } from '@collinsonx/design-system/core';
import useLocale from 'hooks/useLocale';

function NoFlightInfoErrorMessage() {
  const translations = useLocale();
  const messageDescription =
    translations.lounge.errors.noFlightInfoFound.description;

  return <Box>{messageDescription}</Box>;
}

export default NoFlightInfoErrorMessage;
