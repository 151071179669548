import { ApolloError } from '@collinsonx/utils/apollo';
import { BookingError } from 'config/datadog';
import { AVAILABLE_SLOTS_ERRORS } from 'config/graphql';
import fetchErrorObject from 'utils/fetchErrorObject';
import getError from 'utils/getError';

import { HeaderStyle } from './enums';
import { Metadata } from './types';

export function setHeaderTitle(
  headerStyle: HeaderStyle,
  translations: any
): string {
  switch (headerStyle) {
    case HeaderStyle.CAPACITY:
      return translations.capacity.title;
    case HeaderStyle.NO_FLIGHT:
      return translations.noFlightInfoFound.title;
    case HeaderStyle.SERVER:
      return translations.server.title;
    case HeaderStyle.UNKNOWN:
      return translations.unavailable.title;
    default:
      return '';
  }
}

export function errorMetadataIsCorrect(
  response: ApolloError | unknown
): boolean {
  const error = fetchErrorObject(response);

  if (!error) return false;

  const errorPropertiesAreInvalid =
    ('code' in error && 'metadata' in error) === false;

  if (errorPropertiesAreInvalid) return false;

  const metadata = error.metadata as Metadata;

  const metadataPropertiesAreInvalid =
    ('adultCount' in metadata &&
      'childrenCount' in metadata &&
      'infantCount' in metadata) === false;

  if (metadataPropertiesAreInvalid) return false;

  const { adultCount, childrenCount, infantCount } = metadata;
  const maxPropertiesAreInvalid =
    ('max' in adultCount && 'max' in childrenCount && 'max' in infantCount) ===
    false;

  return !maxPropertiesAreInvalid;
}

export function hasLoungeCapacityDefaultError(response: ApolloError | unknown) {
  const error = fetchErrorObject(response);
  if (!error) return false;

  if ('code' in error) {
    const { code } = error;

    return code === AVAILABLE_SLOTS_ERRORS.SNAPLOGIC.ENOUGH_CAPACITY.code;
  }

  return false;
}

export function hasDefaultError(response: ApolloError | unknown): boolean {
  const error = fetchErrorObject(response);
  if (!error) return false;

  return 'code' in error;
}

export function hasInternalServerError(response: unknown): boolean {
  const internalServerError = getError(
    response,
    BookingError.INTERNAL_SERVER_ERROR
  );

  return !!internalServerError;
}

export function hasBadUserInputError(response: unknown): boolean {
  const badUserInputError = getError(response, BookingError.BAD_USER_INPUT);

  return !!badUserInputError;
}
